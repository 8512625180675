<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="visible" @close="handleCancel">
    <a-tabs default-active-key="1"  >
      <a-tab-pane key="1" tab="基本信息">
        <detail-list :col="2">
          <detail-list-item term="用户昵称">{{model.nickname}}</detail-list-item>
          <detail-list-item term="头像">
            <img-preview   :src="model.avatar" :size="60"  style="margin-right: 8px;" />
          </detail-list-item>
          <detail-list-item term="手机号">{{ model.mobile}}</detail-list-item>
          <detail-list-item term="性别"><a-tag    color="green">{{model.sex == 0 ? "保密":(model.sex == 1 ? "男" : "女")}}</a-tag></detail-list-item>
          <detail-list-item  term="生日"><a-tag    color="red">{{model.birthday }}</a-tag></detail-list-item>
        </detail-list>
        <a-card v-if='joinList.length > 0' :bordered="false" style="margin-top: 24px" title="用户认证">
          <detail-list v-for='item in joinList' :col="1" >
            <hr style='margin-top: 10px'>
            <detail-list-item term="认证房源">{{item.village + item.building + item.unit + item.room}}
              <a-tag  style='margin-left: 20px'   color="green">{{item.type == 0 ? "业主" : "租客"}}</a-tag>
              <a-tag  style='margin-left: 5px'   color="red">{{item.approveStatus == 1 ? "已认证" : (item.approveStatus == 0 ? "待审核" : "已驳回")}}</a-tag>
            </detail-list-item>
            <detail-list-item v-if='item.approveStatus == 2' term="驳回原因">{{item.approveContent }}</detail-list-item>
            <detail-list-item term="认证附件">
              <template v-if="item.imgs">
                <img-preview v-for="item in item.imgs.split(',')" :src="item" :size="150"
                             style="margin-right: 8px;" />
              </template>
            </detail-list-item>

          </detail-list>
        </a-card>
      </a-tab-pane>

    </a-tabs>
  </a-drawer>




</template>

<script>

  import DetailList from '@/components/tools/DetailList'
  import ImgPreview from "@/components/crud/table/ImgPreview.vue";
  import dictTag from '@/components/DictTag/index2.vue'
  const DetailListItem = DetailList.Item
  import { userJoinList } from '@/api/village/joinApply'
  export default {
    name: "HouseInfo",
    components: {
      ImgPreview,
      DetailList,dictTag,
      DetailListItem
    },
    dicts: ['info_marriage','info_nation','info_career','info_education','info_income','info_buy_car','info_buy_house','info_expect_married',
      'info_except_child','info_smoke','info_drink','info_interest'  ],
    data () {
      return {
        title:'',
        width:1500,
        visible: false,
        disableSubmit: false,
        lessorShow: false,
        lesseeShow: false,

        query: {},
        model: { },
        joinList:{},
        standardInfo:{},



      }
    },
    created() {

    },

    methods: {
      initValue(record){
        this.model = record
        this.getUserJoinInfo(record.id);
      },
      //认证信息
      getUserJoinInfo(userId){
        userJoinList({"userId":userId}).then(response => {
          this.joinList = response.data
          console.log("=====基本信息=====",this.joinList)
        })
      },

      close () {
        this.$emit('close');
        this.visible = false;
      },
      handleCancel () {
        this.close()
      },
      back(){
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="less" scoped>

  .detail-layout {
    margin-left: 44px;
  }
  .text {
    color: rgba(0, 0, 0, .45);
  }

  .heading {
    color: rgba(0, 0, 0, .85);
    font-size: 20px;
  }

  .no-data {
    color: rgba(0, 0, 0, .25);
    text-align: center;
    line-height: 64px;
    font-size: 16px;

    i {
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: 3px;
    }
  }

  .mobile {
    .detail-layout {
      margin-left: unset;
    }
    .text {

    }
    .status-list {
      text-align: left;
    }
  }
</style>